import { useState, useEffect, useCallback, useContext } from 'react';
import { HermesClientContext } from '@/context/hermesContext';
import { getTimeLeft, getTimeLeftAsText } from '@/util/time-left-service';
import { useHermesSkew } from '@/hooks/useHermesSkew';

export const useAuctionEnd = (endsAt: Date, id: number) => {
  const client = useContext(HermesClientContext);
  const { skew } = useHermesSkew();
  const [timeLeftText, setTimeLeftText] = useState(getTimeLeftAsText(getTimeLeft(endsAt, new Date(), skew)));
  const [loading, setLoading] = useState(false);

  const tickTimeLeft = useCallback(() => {
    // timeLeftSeconds has the "fake" time left, with slacktime subtracted
    const timeLeftSeconds = getTimeLeft(endsAt, new Date(), skew);
    setTimeLeftText(getTimeLeftAsText(timeLeftSeconds));

    // Fetch final update when auction ends
    if (getTimeLeft(endsAt, new Date(), skew, true) < 0) {
      if (loading) return;
      setLoading(true);
      client?.fetchUpdate(id);
    }
  }, [endsAt, skew, client, id, loading]);

  useEffect(() => {
    const timerID = setInterval(tickTimeLeft, 1000);
    return () => clearInterval(timerID);
  }, [tickTimeLeft]);

  return {
    timeLeftText,
  };
};
