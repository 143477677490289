import React from 'react';
import styled from '@emotion/styled';
import { useTokens, Flex, FlexProps } from '@mezzoforte/forge';
import { FocusableBox } from '@/components/Box/FocusableBox';

const { colors, shadows } = useTokens.huutokaupat;

function Wrapper(props: FlexProps) {
  return (
    <Flex
      as="article"
      borderRadius={{ md: 8 }}
      borderBottom={{ base: `1px solid ${colors.lineBorder}`, md: 'none' }}
      boxShadow={{ md: shadows.box }}
      width={{ base: '100%', md: 'auto' }}
      minH={{ base: 'auto', md: 356, lg: 400 }}
      overflow="hidden"
      position="relative"
      {...props}
    />
  );
}

export const EntryListItem = FocusableBox<FlexProps>(styled(Wrapper)`
  content-visibility: auto;
  contain-intrinsic-size: 300px 400px;
`);
