import { useAuctionEnd } from '@/hooks/useAuctionEnd';
import { useAuctionStatus } from '@/hooks/useAuctionStatus';

export interface TimeLeftProps {
  readonly endsAt: Date;
  readonly id: number;
}

export interface TimeLeftDescriptionProps {
  readonly entryId: number;
  readonly isEnded: boolean;
  readonly auctionEnd: Date;
  readonly isContinuousAuction: boolean;
  readonly isCancelled: boolean;
  readonly bidCount: number;
  readonly ignoreOngoing?: boolean;
}

export function TimeLeftDescription({
  entryId,
  isEnded,
  auctionEnd,
  isContinuousAuction,
  isCancelled,
  bidCount,
  ignoreOngoing = false,
}: TimeLeftDescriptionProps) {
  const { status } = useAuctionStatus(
    entryId,
    new Date(),
    auctionEnd,
    isEnded,
    isContinuousAuction,
    isCancelled,
    bidCount
  );
  function statusText() {
    if (status === 'final-check') return 'Tarkistetaan';
    if (status === 'continuing') return 'Jatkuu kohta';
    if (status === 'cancelled') return 'Peruttu';
    if (status === 'ended') return 'Päättynyt';
    return ignoreOngoing ? null : 'Aikaa jäljellä';
  }

  return <>{statusText()}</>;
}

export function TimeLeft({ endsAt, id }: TimeLeftProps) {
  const { timeLeftText } = useAuctionEnd(endsAt, id);
  return timeLeftText;
}
