import { useState, useEffect, useCallback, useContext } from 'react';
import { HermesClientContext } from '@/context/hermesContext';
import { getAuctionStatus, getTimeLeft } from '@/util/time-left-service';
import { useHermesSkew } from '@/hooks/useHermesSkew';

export const useAuctionStatus = (
  entryId: number,
  auctionStart: Date,
  auctionEnd: Date,
  isEnded: boolean,
  isContinuous: boolean,
  isCancelled: boolean,
  bidCount: number
) => {
  const client = useContext(HermesClientContext);
  const { skew } = useHermesSkew();
  const [fetched, setFetched] = useState(false);
  const [status, setStatus] = useState(
    getAuctionStatus(isEnded, isContinuous, isCancelled, bidCount, auctionStart, auctionEnd, skew ?? 0)
  );

  const tickTimeLeft = useCallback(() => {
    setStatus(getAuctionStatus(isEnded, isContinuous, isCancelled, bidCount, auctionStart, auctionEnd, skew ?? 0));

    // Fetch final update when auction ends
    if (getTimeLeft(auctionEnd, new Date(), skew, true) < 0) {
      if (fetched) return;
      setFetched(true);
      client?.fetchUpdate(entryId);
    }
  }, [isEnded, auctionStart, auctionEnd, isCancelled, isContinuous, bidCount, skew, client, entryId, fetched]);

  useEffect(() => {
    const timerID = setInterval(tickTimeLeft, 1000);
    return () => clearInterval(timerID);
  }, [tickTimeLeft]);

  return { status };
};
