import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Text } from '@mezzoforte/forge';
import { NextLinkButton } from '@/components/Link/LinkButton';

interface FavoriteNotLoggedInModalProps {
  onDismiss: (showLoginModal: boolean) => void;
  isOpen: boolean;
}

export function FavoriteNotLoggedInModal({ onDismiss, isOpen }: FavoriteNotLoggedInModalProps) {
  return (
    <Modal onDismiss={() => onDismiss(false)} isOpen={isOpen}>
      <ModalHeader>Kohde on lisätty seurantalistallesi</ModalHeader>
      <ModalBody>
        <Text>
          Kirjautuneena voit tilata tekstarimuistutuksen tunti ennen kohteen päättymistä. Kirjautuneena seurantalistaa
          on helppo seurata kaikilla laitteillasi.
        </Text>
        <Text>Ei tunnusta vielä? Tee täysin ilmainen tunnus parissa minuutissa. </Text>
      </ModalBody>
      <ModalFooter>
        <NextLinkButton fontSize="sm" href="/tee-tunnus">
          Tee tunnus
        </NextLinkButton>
        <Button
          variant="highlight"
          onClick={() => {
            onDismiss(true);
          }}
        >
          Kirjaudu sisään
        </Button>
      </ModalFooter>
    </Modal>
  );
}
