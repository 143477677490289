import { isMezzoNetAuction } from '@/util/company';

export interface CompanyNameProps {
  companyName: string;
  companyId: number;
  isOwnPayment: boolean;
}

export function CompanyName({ companyName, companyId, isOwnPayment }: CompanyNameProps) {
  const suffix = isOwnPayment || isMezzoNetAuction(companyId) ? 'myy' : 'ilmoittaa, Huutokaupat.com myy';
  return `${companyName} ${suffix}`;
}
