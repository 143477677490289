import React from 'react';
import styled from '@emotion/styled';
import { useTokens } from '@mezzoforte/forge';

const { colors } = useTokens.huutokaupat;

export const FocusableBox = <T extends object>(Component: React.FC) => styled<React.FC<React.PropsWithChildren<T>>>(
  Component
)`
  :has(:hover),
  :has(:active) {
    outline: 1px solid ${colors.inputBorder};

    /* sidestep Safari bug, remove outline on hover */
    @supports (background: - webkit-named-image(i)) {
      outline: none;
    }
  }
  :has(:focus) {
    box-shadow:
      0 4px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px rgba(192, 86, 33, 0.6);
  }
`;
