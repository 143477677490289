import { useContext, useEffect, useState } from 'react';
import { HermesClientContext } from '@/context/hermesContext';

export const useHermesSkew = () => {
  const client = useContext(HermesClientContext);
  const [skew, setSkew] = useState(client?.getSkew());

  useEffect(() => {
    const listener = (newSkew: number) => setSkew(newSkew);
    client?.on('skew', listener);

    return () => {
      client?.off('skew', listener);
    };
  }, [client]);

  return {
    skew,
  };
};
