import { useContext, useEffect, useState } from 'react';
import { HermesClientContext } from '@/context/hermesContext';
import { HermesEntryAPIResponse } from '@/types/ApiResponse';
import { HermesEntry } from '@/types/ListEntry';

export const useHermesEntry = (id: number, vatPerc: number) => {
  const [hermesEntry, setHermesEntry] = useState<HermesEntry>();
  const [isFetchingUpdate, setIsFetchingUpdate] = useState(false);

  const client = useContext(HermesClientContext);

  useEffect(() => {
    client?.subscribe([{ id, vatPerc }]);
    return () => {
      client?.unsubscribe([{ id, vatPerc }]);
    };
  }, [client, id, vatPerc]);

  useEffect(() => {
    setHermesEntry(client?.entries.get(id));
    const listener = (entry: HermesEntryAPIResponse) => {
      // Client will send all updates to all useHermes hooks, filter here.
      if (entry.id !== id) return;
      setHermesEntry(client?.entries.get(id));
      setIsFetchingUpdate(false);
    };

    client?.on('update', listener);

    return () => {
      client?.off('update', listener);
    };
  }, [id, client, vatPerc]);

  return {
    hermesEntry,
    client,
    skew: client?.getSkew() ?? 0,
    fetchUpdate: () => {
      if (isFetchingUpdate) return;

      client?.fetchUpdate(id);
      setIsFetchingUpdate(true);
    },
  };
};
