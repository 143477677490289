import { isNil } from 'lodash-es';
import { EntryMetadata } from '@/types/Metadata/Metadata';

export function getEmissionManipulation(
  entryMetadata: EntryMetadata | null | undefined
): { emissionManipulation: boolean; emissionManipulationDescription: string | null } | null {
  if (!entryMetadata) {
    return null;
  }

  if (
    entryMetadata.baseCategory === 'IndustrialMachinery' ||
    entryMetadata.category === 'PassengerCar' ||
    entryMetadata.category === 'RegistrableVehicle' ||
    entryMetadata.category === 'Truck'
  ) {
    return {
      emissionManipulation: !!entryMetadata.emissionManipulation,
      emissionManipulationDescription: entryMetadata.emissionManipulationDescription,
    };
  }

  return null;
}

export function getManufacturer(metadata: EntryMetadata | null | undefined): string | undefined {
  return !isNil(metadata) && 'manufacturer' in metadata ? String(metadata.manufacturer) : undefined;
}
