import { getManufacturer } from '@/util/metadata';
import { createEcommerceItem } from '@/util/gtm-service';
import { ListEntry } from '@/types/ListEntry';
import { CategoryDynamicData } from '@/types/Category';
import { priceWithoutVat } from '@/util/price';

export function listEntryToEcommerceItem(
  entry: ListEntry,
  categories: CategoryDynamicData[],
  indexInList: number
): Gtag.Item {
  return createEcommerceItem({
    id: String(entry.id),
    name: entry.title,
    company: entry.companyName ?? '',
    brand: getManufacturer(entry.metadata),
    category: { id: entry.categoryId, name: entry.categoryName },
    parentCategory: categories.find((category) => category.id === entry.parentCategoryId),
    location: `${entry.zipCode} ${entry.city}`,
    priceWithoutShippingOrTax: priceWithoutVat(entry.highestBid ?? entry.startPrice).amount,
    index: indexInList,
  });
}
