import { getTimeLeft } from '@/util/time-left-service';
import { useHermesEntry } from '@/hooks/useHermesEntry';
import { useFavorites } from '@/hooks/useFavorites';

const smsNotificationThresholdSeconds = 60 * 60; // 1 hour

export function useShouldDisplaySmsNotificationToggle(id: number, vatPerc: number) {
  const { hasFavorite } = useFavorites();
  const { hermesEntry, skew } = useHermesEntry(id, vatPerc);

  return {
    shouldDisplaySmsNotificationToggle:
      hasFavorite(id) &&
      hermesEntry !== undefined &&
      getTimeLeft(new Date(hermesEntry.auctionEnd), new Date(), skew) > smsNotificationThresholdSeconds,
  };
}
