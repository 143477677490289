import { Flex, Checkbox } from '@mezzoforte/forge';
import { useFavorites } from '@/hooks/useFavorites';
import { useShouldDisplaySmsNotificationToggle } from '@/hooks/useShouldDisplaySmsNotificationToggle';

interface SmsNotificationToggleProps {
  entryId: number;
  vatPerc: number;
}

export function ListSmsNotificationToggle({ entryId, vatPerc }: SmsNotificationToggleProps) {
  const { hasSmsNotification, toggleSmsNotification } = useFavorites();
  const { shouldDisplaySmsNotificationToggle } = useShouldDisplaySmsNotificationToggle(entryId, vatPerc);

  if (!shouldDisplaySmsNotificationToggle) return null;

  return (
    <Flex align="center" zIndex={11}>
      <Checkbox
        size={{ base: 'sm', md: 'md' }}
        isReadOnly={toggleSmsNotification.status === 'pending'}
        onChange={() => toggleSmsNotification.mutate(entryId)}
        isChecked={hasSmsNotification(entryId)}
      >
        Tekstarimuistutus
      </Checkbox>
    </Flex>
  );
}
