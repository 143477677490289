export const MEZZOFORTE_OY_COMPANY_IDS = [6129, 9440];
export const MEZZOFORTE_OY_COMPANY_KUHU = 6129; // aka ConsumerNetAuction

export function isConsumerNetAuction(companyId: number) {
  return MEZZOFORTE_OY_COMPANY_KUHU === companyId;
}

export function isMezzoNetAuction(companyId: number) {
  return MEZZOFORTE_OY_COMPANY_IDS.includes(companyId);
}
