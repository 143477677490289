import { EmbeddedEntry, ListEntry, DeliveryMethod, DeliveryType } from '@/types/ListEntry';
import { addVatToPrice } from '@/util/price';
import type {
  EmbeddedEntryAPIResponse,
  EntryAPIResponse,
  EntryListItem,
  EntryPageAPIResponse,
  FrontPageEntryAPIResponse,
} from '@/types/ApiResponse';

export function apiToEntry(entry: EmbeddedEntryAPIResponse): EmbeddedEntry;
export function apiToEntry(entry: FrontPageEntryAPIResponse): ListEntry;
export function apiToEntry(entry: EntryAPIResponse): ListEntry;
export function apiToEntry<T extends EntryAPIResponse>(entry: T) {
  return {
    ...entry,
    slug: entry.slug ?? '',
    highestBid:
      entry.highestBid !== null
        ? addVatToPrice(
            {
              amount: entry.highestBid,
            },
            entry.vatPerc
          )
        : null,
    startPrice: addVatToPrice(
      {
        amount: entry.startPrice,
      },
      entry.vatPerc
    ),
  };
}
export const linkToEntryPage = (entry: ListEntry): string =>
  `/kohde/${entry.publicId}/${entry.slug}/${
    entry.source ? `?${new URLSearchParams({ source: entry.source }).toString()}` : ''
  }`;

export function deliveryMethodsForEntry(entry: EntryPageAPIResponse['entry']): DeliveryMethod[] {
  return (entry.delivery?.selectableMethods ?? []).map((type) => {
    switch (type) {
      case DeliveryType.DELIVERY:
        return {
          type,
          expenses: entry.delivery?.deliveryExpenses ?? null,
          label: 'Toimitus',
          expensesLabel: 'Toimituskulut',
          additionalNote: 'Toimituskulut koskevat toimitusta Manner-Suomeen.',
          notAvailableLabel: 'Toimitus ei mahdollinen',
        };
      case DeliveryType.RETRIEVAL:
        return {
          type,
          expenses: entry.delivery?.retrievalExpenses ?? null,
          label: 'Nouto',
          expensesLabel: 'Noutokulut',
          additionalNote: '',
          notAvailableLabel: 'Nouto ei mahdollinen',
        };
      default:
        throw new Error(`Unknown DeliveryType ${String(type)}`);
    }
  });
}

export function entryListItemToListEntry(entry: EntryListItem): ListEntry {
  return {
    ...entry,
    cancelled: entry.isCancelled,
    ended: entry.biddingEnded,
    highestBid: entry.highestBid !== null ? addVatToPrice({ amount: entry.highestBid }, entry.vatPerc) : null,
    isFastBidPeriodEntry: entry.isFastBidEntry,
    isOwnPayment: entry.ownPayment,
    mainPhoto: entry.photoUrl,
    publicId: entry.id,
    soldToHighestBidder: entry.isSoldToHighestBidder,
    startPrice: addVatToPrice({ amount: entry.startPrice }, entry.vatPerc),
  };
}
